<template>
  <div class="body">
    <div><img src="./success.svg" style="width:60px"/></div>
    <div>授权成功啦！请关注公众号接收交易通知</div>
    <div style="margin-top: 50px"><img src="./wechat.jpeg" style="width:250px"/></div>
  </div>
</template>

<script>
export default {
  data: function (){
    return { }
  },

  mounted() { },

  methods: { }

}
</script>
<style lang="css" scoped>
.body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
}
</style>